@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro SemiBold'), local('Source-Sans-Pro-SemiBold'),
    url('./static/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Black'), local('Source-Sans-Pro-Black'),
    url('./static/fonts/SourceSansPro/SourceSansPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}